import * as React from 'react'
import { Helmet } from "react-helmet"
import { Link, useStaticQuery, graphql } from 'gatsby'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'

import {
  container,
  heading,
  nav,
  navLinks,
  navLinkItem,
  navLinkItemSelected,
  navLinkText,
  siteTitle,
  staySmall
} from './layout.module.css'
import './layout.css'

const Layout = ({ pageTitle, children, style }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const pages = ["home", "posts", "projects"];
  return (
    <div className={container} style={style}>
      <Helmet>
        <link href="https://fonts.googleapis.com/css?family=Muli:300,400,500,500&display=swap" rel="stylesheet" />
        <title>{`${data.site.siteMetadata.title} | ${pageTitle}`}</title>
      </Helmet>
      <nav className={nav}>
        <Link to="/" className={siteTitle}>{data.site.siteMetadata.title}</Link>
        <div className={navLinks}>
          {pages.map(((page, idx) => (
            <div key={idx} className={pageTitle === page ? navLinkItemSelected : navLinkItem}><Link className={navLinkText} to={`/${page === "home" ? "" : page}`}>{page}</Link></div>
          )))}
          <div className={navLinkItem}>
            <ThemeToggler>
              {({ theme, toggleTheme }) => (
                <label style={{ color: 'var(--textNormal)', display: "flex" }}>
                  <input
                    type="checkbox"
                    onChange={e => toggleTheme(e.target.checked ? 'dark' : 'light')}
                    checked={theme === 'dark'}
                    style={{ marginRight: "4px" }}
                  />{' '}
                  <svg xmlns="http://www.w3.org/2000/svg" fill={theme === 'dark' ? 'none' : 'currentColor'} viewBox="0 0 24 24" stroke="currentColor" width="20px">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
                  </svg>
                </label>
              )}
            </ThemeToggler>
          </div>
        </div>
      </nav>
      {!pages.includes(pageTitle) && <h1 className={heading}>{pageTitle}</h1>}
      {children}
    </div>
  )
}

export default Layout